import { AccessGroupPermissions, AccountingPermissions, AlertsPermissions, AutopayPlanPermissions, CheckPermissions, ClaimPermissions, CodePermissions, CommissionsPermissions, DashboardPermissions, DocumentTemplatePermissions, FAQPermissions, FormsPermissions, GeneralPermissions, IncidentPermissions, IntegrationPermissions, InvoicesPermissions, LedgerAccountsPermissions, LineItemTypesPermissions, LossRunPermissions, MarketingLibraryPermissions, MessagePermissions, PayrollPermissions, PolicyAuditPermissions, PolicyPaymentsPermissions, PolicyPermissions, PolicyholderPermissions, QuotePermissions, RatePermissions, RatingPermissions, ReinstatementsPermissions, RenewalsPermissions, RoleCategories, RolePermissions, SubmissionsPermissions, SurplusCalculationPermissions, UserPermissions, ViewsPermissions, WorkflowPermissions, generalCrudPermissionMap, generalFilePermissionMap, generalLinkedContactPermissions, generalNotePermissionMap, generalOthersRecordsPermissionMap, generalTaskPermissionMap, getGeneralRoleSet, } from '../roleEnums';
export const applicationRoleSet = [
    {
        id: RoleCategories.Dashboard,
        readableName: 'Dashboard',
        description: 'Dashboard Feature Permissions',
        granularPermissions: [
            {
                id: DashboardPermissions.AgencyFilter,
                readableName: 'Show Filter by Agency',
                description: 'Dynamic filtering of results by Agency',
                enabled: false,
            },
            {
                id: DashboardPermissions.Share,
                readableName: 'Share a dashboard',
                description: 'Share a dashboard with your organization',
                enabled: false,
            },
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Views,
        readableName: 'Views',
        description: 'Views Feature Permissions',
        granularPermissions: [
            {
                id: ViewsPermissions.CreateSharedView,
                readableName: 'Create a shared view',
                description: 'Create a view and share it with your organization',
                enabled: false,
            },
            {
                id: ViewsPermissions.CreateFormulaFields,
                readableName: 'Create formula fields',
                description: 'Create formula fields while editing views',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Submissions,
        readableName: 'Submissions',
        description: 'Submissions Feature Permissions',
        granularPermissions: [
            {
                id: SubmissionsPermissions.CreateQuoteFromSubmission,
                readableName: 'Create Quote from Submission',
                description: 'Create Quote from Submission',
                enabled: false,
            },
            {
                id: SubmissionsPermissions.CreateQuickQuote,
                readableName: 'Create a Quick Quote',
                description: 'Create a Quick Quote',
                enabled: false,
            },
            {
                id: SubmissionsPermissions.CreateApplication,
                readableName: 'Create a Full Application',
                description: 'Create a Full Application',
                enabled: false,
            },
            {
                id: GeneralPermissions.ExportData,
                readableName: 'Export Data',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.CreateFiles,
                readableName: 'Create Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.DeleteFiles,
                readableName: 'Delete Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.ViewFiles,
                readableName: 'View Files',
                description: '',
                enabled: false,
            },
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
        enabled: false,
    },
    {
        id: RoleCategories.FormsLibrary,
        readableName: 'Forms Library',
        description: 'Forms Library Feature Permissions',
        granularPermissions: [
            {
                id: FormsPermissions.UploadNew,
                readableName: 'Upload new form',
                description: 'Upload a new form to the Forms Library',
                enabled: false,
            },
            {
                id: FormsPermissions.Delete,
                readableName: 'Delete form',
                description: 'Remove Form from the Forms Library',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.LossRuns,
        readableName: 'Loss Runs',
        description: 'Loss Runs Feature Permissions',
        enabled: false,
        granularPermissions: [
            {
                id: LossRunPermissions.LimitByPolicyDate,
                readableName: 'Limit by Policy Date',
                description: 'Limit by Policy Date',
            },
        ],
    },
    {
        id: RoleCategories.MarketingLibrary,
        readableName: 'Marketing Library',
        description: 'Marketing Library Feature Permissions',
        granularPermissions: [
            {
                id: MarketingLibraryPermissions.UploadNew,
                readableName: 'Upload new marketing document',
                description: 'Upload a new marketing document to the Marketing Library',
                enabled: false,
            },
            {
                id: MarketingLibraryPermissions.Delete,
                readableName: 'Delete marketing document',
                description: 'Remove document from the Marketing Library',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.AgencyManagement,
        readableName: 'Agency Management',
        description: 'Agency Management Feature Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Messages,
        readableName: 'Messages',
        description: 'Messages Feature Permissions',
        granularPermissions: [
            {
                id: MessagePermissions.Create,
                readableName: 'Create messages',
                description: 'Send messages to others',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Renewals,
        readableName: 'Renewals',
        description: 'Renewals Feature Permissions',
        granularPermissions: [
            {
                id: RenewalsPermissions.GenerateQuote,
                readableName: 'Generate Quote',
                description: '',
                enabled: false,
            },
            {
                id: RenewalsPermissions.ManageRenewalRules,
                readableName: 'Manage Renewal Rules',
                description: '',
                enabled: false,
            },
        ],
    },
    {
        id: RoleCategories.Quotes,
        readableName: 'Quotes',
        description: 'Quotes Feature Permissions',
        granularPermissions: [
            {
                id: QuotePermissions.Rules,
                readableName: 'Manage Rules',
                description: '',
                enabled: false,
            },
            {
                id: QuotePermissions.RequestInfo,
                readableName: 'Request Info',
                description: '',
                enabled: false,
            },
            {
                id: QuotePermissions.Edit,
                readableName: 'Edit Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Bind,
                readableName: 'Bind Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Approve,
                readableName: 'Approve Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Unapprove,
                readableName: 'Unapprove Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Deny,
                readableName: 'Deny Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Accept,
                readableName: 'Accept Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Unaccept,
                readableName: 'Unaccept Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Decline,
                readableName: 'Decline Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Reassign,
                readableName: 'Reassign Quote',
                enabled: false,
            },
            {
                id: QuotePermissions.Print,
                readableName: 'Print Quote',
                description: 'Preview/Download Quote PDF',
                enabled: false,
            },
            {
                id: QuotePermissions.Email,
                readableName: 'Email Quote',
                description: 'Email Quote PDF',
                enabled: false,
            },
            {
                id: GeneralPermissions.CreateFiles,
                readableName: 'Create Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.DeleteFiles,
                readableName: 'Delete Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.ViewFiles,
                readableName: 'View Files',
                description: '',
                enabled: false,
            },
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Commissions,
        readableName: 'Agency Commissions',
        description: 'Agency Commissions Permissions',
        granularPermissions: [
            {
                id: CommissionsPermissions.ManageSettings,
                readableName: 'Manage Agency Commission Settings',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.PolicyAudits,
        readableName: 'Policy Audit',
        description: 'Policy Audit Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            {
                id: PolicyAuditPermissions.Approve,
                readableName: 'Approve',
                description: '',
                enabled: false,
            },
            {
                id: PolicyAuditPermissions.MakePayment,
                readableName: 'Make payment',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.Administer,
                readableName: 'Administer',
                description: '',
                enabled: false,
            },
            {
                id: PolicyAuditPermissions.ModifyApprovedAudit,
                readableName: 'Modify Approved Audits',
                description: '',
                enabled: false,
            },
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
        ],
    },
    {
        id: RoleCategories.PolicyAuditDisputes,
        readableName: 'Policy Audit Dispute',
        description: 'Policy Audit Dispute Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
    },
    {
        id: RoleCategories.Payroll,
        readableName: 'Payroll',
        description: 'Payroll Feature Permissions',
        granularPermissions: [
            {
                id: PayrollPermissions.AddNew,
                readableName: 'Add a new payroll worksheet',
                description: '',
                enabled: false,
            },
            {
                id: PayrollPermissions.Update,
                readableName: 'Update existing payroll worksheet',
                description: '',
                enabled: false,
            },
            {
                id: PayrollPermissions.MakePayment,
                readableName: 'Make payroll worksheet payment',
                description: '',
                enabled: false,
            },
            {
                id: PayrollPermissions.DeleteOpenWorksheet,
                readableName: 'Delete an open worksheet',
                description: '',
                enabled: false,
            },
            {
                id: PayrollPermissions.ModifySubmittedWorksheet,
                readableName: 'Modify a submitted worksheet',
                description: '',
                enabled: false,
            },
            {
                id: PayrollPermissions.ModifyPaidWorksheet,
                readableName: 'Modify a paid worksheet',
                description: '',
                enabled: false,
            },
            {
                id: PayrollPermissions.DeleteSubmittedWorksheet,
                readableName: 'Delete a submitted worksheet',
                description: '',
                enabled: false,
            },
            {
                id: PayrollPermissions.ConfigurePayrollSettings,
                readableName: 'Configure Settings',
                description: '',
                enabled: false,
            },
            {
                id: PayrollPermissions.SpecifySubmittedDate,
                readableName: 'Specify Submitted Date',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.PolicyPayments,
        readableName: 'Policy Payments',
        description: 'Policy Payments Feature Permissions',
        granularPermissions: [
            {
                id: PolicyPaymentsPermissions.OneTimePayment,
                readableName: 'Make one time policy payment',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPaymentsPermissions.LogPayment,
                readableName: 'Log payment',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPaymentsPermissions.LogRefund,
                readableName: 'Log refund',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPaymentsPermissions.SavePaymentMethod,
                readableName: 'Save payment method',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPaymentsPermissions.ReversePayment,
                readableName: 'Reverse payment',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPaymentsPermissions.ReallocatePayment,
                readableName: 'Reallocate payment',
                description: '',
                enabled: false,
            },
            { id: GeneralPermissions.Administer, readableName: 'Administer', enabled: false },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Invoices,
        readableName: 'Invoices',
        description: 'Invoices Feature Permissions',
        granularPermissions: [
            {
                id: InvoicesPermissions.AddNew,
                readableName: 'Add a new invoice',
                description: '',
                enabled: false,
            },
            {
                id: InvoicesPermissions.Edit,
                readableName: 'Edit an existing invoice',
                description: '',
                enabled: false,
            },
            {
                id: InvoicesPermissions.WriteOff,
                readableName: 'Write off invoice/line-items',
                description: '',
                enabled: false,
            },
            {
                id: InvoicesPermissions.Delete,
                readableName: 'Delete an existing invoice',
                description: '',
                enabled: false,
            },
            {
                id: InvoicesPermissions.ConfigureInvoice,
                readableName: 'Configure invoice generation',
                description: '',
                enabled: false,
            },
            {
                id: InvoicesPermissions.Print,
                readableName: 'Print invoice',
                description: 'Download/Email Invoice PDF',
                enabled: false,
            },
            {
                id: InvoicesPermissions.AddInstallmentPlan,
                readableName: 'Add Installment Plans',
                description: 'Add Installment Plans to existing invoices to split up payments',
                enabled: false,
            },
            {
                id: GeneralPermissions.CreateFiles,
                readableName: 'Create Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.DeleteFiles,
                readableName: 'Delete Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.ViewFiles,
                readableName: 'View Files',
                description: '',
                enabled: false,
            },
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
    },
    {
        id: RoleCategories.LineItemTypes,
        readableName: 'Line Item Types',
        description: 'Line Item Types Permissions',
        enabled: false,
        granularPermissions: [
            {
                id: LineItemTypesPermissions.AddNew,
                readableName: 'Add new line item type',
                description: '',
                enabled: false,
            },
            {
                id: LineItemTypesPermissions.Edit,
                readableName: 'Edit line item type',
                description: '',
                enabled: false,
            },
            {
                id: LineItemTypesPermissions.Delete,
                readableName: 'Delete line item type',
                description: '',
                enabled: false,
            },
        ],
    },
    {
        id: RoleCategories.AutopayPlans,
        readableName: 'Autopay Plans',
        description: 'Autopay Plans Permissions',
        enabled: false,
        granularPermissions: [
            {
                id: AutopayPlanPermissions.AddNew,
                readableName: 'Add a new autopay plan',
                description: '',
                enabled: false,
            },
            {
                id: AutopayPlanPermissions.Edit,
                readableName: 'Edit autopay plan',
                description: '',
                enabled: false,
            },
            {
                id: AutopayPlanPermissions.Delete,
                readableName: 'Delete autopay plan',
                description: '',
                enabled: false,
            },
        ],
    },
    {
        id: RoleCategories.Accounting,
        readableName: 'Accounting',
        description: 'Accounting Feature Permissions',
        granularPermissions: [
            {
                id: AccountingPermissions.ViewDeposits,
                readableName: 'View Deposits',
                description: '',
                enabled: false,
            },
        ],
    },
    {
        id: RoleCategories.LedgerAccounts,
        readableName: 'Ledger Accounts',
        description: 'Ledger Accounts Feature Permissions',
        granularPermissions: [
            {
                id: LedgerAccountsPermissions.AdminLedgerCodes,
                readableName: 'Manage Ledger Account Codes',
                description: '',
                enabled: false,
            },
        ],
    },
    {
        id: RoleCategories.Alerts,
        readableName: 'Alerts',
        description: 'Alerts Feature Permissions',
        granularPermissions: [
            {
                id: AlertsPermissions.Create,
                readableName: 'Create alert',
                description: '',
                enabled: false,
            },
            {
                id: AlertsPermissions.Edit,
                readableName: 'Edit alert',
                description: '',
                enabled: false,
            },
            {
                id: AlertsPermissions.Delete,
                readableName: 'Delete alert',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Users,
        readableName: 'Users',
        description: 'User Management Feature Permissions',
        granularPermissions: [
            {
                id: UserPermissions.CreateUser,
                readableName: 'Create user',
                description: '',
                enabled: false,
            },
            {
                id: UserPermissions.EditUser,
                readableName: 'Edit user',
                description: '',
                enabled: false,
            },
            {
                id: UserPermissions.DeleteUser,
                readableName: 'Deactivate/Reactivate user',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Rates,
        readableName: 'Rates',
        description: 'Rate Management Feature Permissions',
        granularPermissions: [
            {
                id: RatePermissions.EditTable,
                readableName: 'Edit Rate',
                description: '',
                enabled: false,
            },
            {
                id: RatePermissions.DeleteTable,
                readableName: 'Delete Rate',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Rating,
        readableName: 'Rating',
        description: 'Rating Management Feature Permissions',
        granularPermissions: [
            {
                id: RatingPermissions.CreateFormula,
                readableName: 'Create Formula',
                description: '',
                enabled: false,
            },
            {
                id: RatingPermissions.EditFormula,
                readableName: 'Edit Formula',
                description: '',
                enabled: false,
            },
            {
                id: RatingPermissions.DeleteFormula,
                readableName: 'Delete Formula',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Roles,
        readableName: 'Roles',
        description: 'Role Management Feature Permissions',
        granularPermissions: [
            {
                id: RolePermissions.CreateRole,
                readableName: 'Create role',
                description: '',
                enabled: false,
            },
            {
                id: RolePermissions.EditRole,
                readableName: 'Edit role',
                description: '',
                enabled: false,
            },
            {
                id: RolePermissions.DeleteRole,
                readableName: 'Delete role',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.AccessGroups,
        readableName: 'Access Groups',
        description: 'Access Group Management Feature Permissions',
        granularPermissions: [
            {
                id: AccessGroupPermissions.CreateAccessGroup,
                readableName: 'Create Access Group',
                description: '',
                enabled: false,
            },
            {
                id: AccessGroupPermissions.EditAccessGroup,
                readableName: 'Edit Access Group',
                description: '',
                enabled: false,
            },
            {
                id: AccessGroupPermissions.DeleteAccessGroup,
                readableName: 'Delete Access Group',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Codes,
        readableName: 'Code Sets',
        description: 'Code Sets Management Feature Permissions',
        granularPermissions: [
            {
                id: CodePermissions.AddCodeSets,
                readableName: 'Add Code Sets',
                description: '',
                enabled: false,
            },
            {
                id: CodePermissions.EditCodeSets,
                readableName: 'Edit Code Sets',
                description: '',
                enabled: false,
            },
            {
                id: CodePermissions.DeleteCodeSets,
                readableName: 'Delete Code Sets',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.DocumentTemplates,
        readableName: 'Document Templates',
        description: 'Document Template Feature Permissions',
        granularPermissions: [
            {
                id: DocumentTemplatePermissions.ManageDocumentTemplates,
                readableName: 'Manage Templates',
                description: '',
                enabled: false,
            },
            {
                id: DocumentTemplatePermissions.GenerateResults,
                readableName: 'Generate Results',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Workflows,
        readableName: 'Workflows',
        description: 'Workflow Feature Permissions',
        granularPermissions: [
            {
                id: WorkflowPermissions.Create,
                readableName: 'Create',
                description: '',
                enabled: false,
            },
            {
                id: WorkflowPermissions.Edit,
                readableName: 'Edit',
                description: '',
                enabled: false,
            },
            {
                id: WorkflowPermissions.Delete,
                readableName: 'Delete',
                description: '',
                enabled: false,
            },
            {
                id: WorkflowPermissions.Run,
                readableName: 'Run',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.FAQ,
        readableName: 'FAQs',
        description: 'FAQs Feature Permissions',
        granularPermissions: [
            {
                id: FAQPermissions.Create,
                readableName: 'Create FAQ',
                description: '',
                enabled: false,
            },
            {
                id: FAQPermissions.Edit,
                readableName: 'Edit FAQ',
                description: '',
                enabled: false,
            },
            {
                id: FAQPermissions.Delete,
                readableName: 'Delete FAQ',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Policies,
        readableName: 'Policies',
        description: 'Policies Feature Permissions',
        granularPermissions: [
            {
                id: GeneralPermissions.ExportData,
                readableName: 'Export Data',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPermissions.ExportLossRun,
                readableName: 'Export Claim Loss Run',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.ViewMessages,
                readableName: 'Messages',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.CreateFiles,
                readableName: 'Create Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.DeleteFiles,
                readableName: 'Delete Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.ViewFiles,
                readableName: 'View Files',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPermissions.Admin,
                readableName: 'Administration',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPermissions.CancelPolicy,
                readableName: 'Cancel Policy',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPermissions.RescindPendingCancellation,
                readableName: 'Rescind Pending Cancellation',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPermissions.EndorseExpiredPolicies,
                readableName: 'Endorse expired policies',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPermissions.CreateEndorsement,
                readableName: 'Create Endorsement',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPermissions.BindEndorsement,
                readableName: 'Bind Endorsement',
                description: '',
                enabled: false,
            },
            {
                id: PolicyPermissions.DeleteEndorsement,
                readableName: 'Delete Endorsement',
                description: '',
                enabled: false,
            },
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Reinstatements,
        readableName: 'Reinstatements',
        description: 'Reinstatments Feature Permissions',
        granularPermissions: [
            {
                id: ReinstatementsPermissions.EditReinstatementRules,
                readableName: 'Edit Reinstatement Rules',
                description: '',
                enabled: false,
            },
            {
                id: ReinstatementsPermissions.AllowReinstatements,
                readableName: 'Allow Reinstatements',
                description: '',
                enabled: false,
            },
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Checks,
        readableName: 'Checks',
        description: 'Check Feature Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet({
                [CheckPermissions.CanOverridePayee]: 'Can Override Payee',
                [CheckPermissions.CanClearCheck]: 'Can Clear Check',
                [CheckPermissions.CanVoidOrStop]: 'Can Void or Stop Check',
            }),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
    },
    {
        id: RoleCategories.CheckBatches,
        readableName: 'Check Batches',
        description: 'Check Batch Feature Permissions',
        granularPermissions: [
            ...getGeneralRoleSet({
                [GeneralPermissions.Create]: 'Create',
                ['print-documents']: 'Print Documents',
            }),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
        ],
    },
    {
        id: RoleCategories.BankAccounts,
        readableName: 'Bank Accounts',
        description: 'Bank Account Feature Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
        ],
    },
    {
        id: RoleCategories.Claims,
        readableName: 'Claims',
        description: 'Claims Feature Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            { id: ClaimPermissions.ManageStatus, readableName: 'Manage Status' },
            { id: ClaimPermissions.LogPayments, readableName: 'Log Payments' },
            { id: ClaimPermissions.LogRecovery, readableName: 'Log Recoveries' },
            { id: ClaimPermissions.SetReserves, readableName: 'Set Reserves' },
            { id: GeneralPermissions.Administer, readableName: 'Administer' },
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
            ...getGeneralRoleSet(generalLinkedContactPermissions),
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Incidents,
        readableName: 'Incidents',
        description: 'Incident Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet(generalOthersRecordsPermissionMap),
            {
                id: IncidentPermissions.ManageStatus,
                readableName: 'Manage Status',
                description: '',
                enabled: false,
            },
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
            ...getGeneralRoleSet(generalLinkedContactPermissions),
            {
                id: GeneralPermissions.Administer,
                readableName: 'Administer',
                description: '',
                enabled: false,
            },
        ],
    },
    {
        id: RoleCategories.Contacts,
        readableName: 'Contacts',
        description: 'Contact Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
    },
    {
        id: RoleCategories.Locations,
        readableName: 'Locations',
        description: 'Location Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
    },
    {
        id: RoleCategories.Carriers,
        readableName: 'Carriers',
        description: 'Carrier Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
        ],
    },
    {
        id: RoleCategories.Employers,
        readableName: 'Employers',
        description: 'Employer Permissions',
        granularPermissions: [
            ...getGeneralRoleSet(generalCrudPermissionMap),
            ...getGeneralRoleSet(generalFilePermissionMap),
            ...getGeneralRoleSet(generalNotePermissionMap),
        ],
    },
    {
        id: RoleCategories.DataModel,
        readableName: 'Data Model',
        description: 'Manage Data Model Access',
        granularPermissions: [],
        enabled: false,
    },
    {
        id: RoleCategories.TrailMaps,
        readableName: 'Trail Maps',
        description: 'Manage Trail Map Access',
        granularPermissions: [],
        enabled: false,
    },
    {
        id: RoleCategories.Policyholders,
        readableName: 'Policyholders',
        description: 'Policyholder Feature Permissions',
        granularPermissions: [
            {
                id: PolicyholderPermissions.Create,
                readableName: 'Create Policyholder',
                description: '',
                enabled: false,
            },
            {
                id: PolicyholderPermissions.Edit,
                readableName: 'Edit Policyholder',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.CreateFiles,
                readableName: 'Create Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.DeleteFiles,
                readableName: 'Delete Files',
                description: '',
                enabled: false,
            },
            {
                id: GeneralPermissions.ViewFiles,
                readableName: 'View Files',
                description: '',
                enabled: false,
            },
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
        enabled: false,
    },
    {
        id: RoleCategories.SurplusCalculations,
        readableName: 'Surplus Calculations',
        description: 'Surplus Calculations Feature Permissions',
        granularPermissions: [
            {
                id: SurplusCalculationPermissions.Create,
                readableName: 'Create Surplus Calculation',
                description: '',
                enabled: false,
            },
            {
                id: SurplusCalculationPermissions.Reclculate,
                readableName: 'Recalculate Surplus Calculation',
                description: '',
                enabled: false,
            },
            ...getGeneralRoleSet(generalNotePermissionMap),
            ...getGeneralRoleSet(generalTaskPermissionMap),
        ],
        enabled: false,
    },
    {
        id: RoleCategories.Integrations,
        readableName: 'Integrations',
        description: 'Integrations Feature Permissions',
        granularPermissions: [
            {
                id: IntegrationPermissions.Create,
                readableName: 'Create Integration',
                description: 'Create an integration into another system via API',
                enabled: false,
            },
            {
                id: IntegrationPermissions.Edit,
                readableName: 'Edit Integration',
                description: 'Edit an existing integration',
                enabled: false,
            },
        ],
        enabled: false,
    },
];
export function permissionExists(roleCategory, permission) {
    const rolePermissionCategory = applicationRoleSet.find((x) => x.id === roleCategory);
    //console.log(`role permission category`, rolePermissionCategory);
    return rolePermissionCategory?.granularPermissions?.find((x) => x?.id === permission) ?? false;
}
