var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
const recordTypeId = 'BankAccount';
let BankAccount = class BankAccount {
    id;
    name;
    accountNumber;
    routingNumber;
    accountType;
    accountHolderName;
    bankName;
    bankBranch;
    tin;
    accountHolderAddress;
    accountHolderPhone;
    accountHolderEmail;
    lastSequenceUsed;
    claimFilterGroup;
    isDisabled;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], BankAccount.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], BankAccount.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], BankAccount.prototype, "accountNumber", void 0);
__decorate([
    FieldData({ recordTypeId })
], BankAccount.prototype, "routingNumber", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'codelist',
        codeList: [
            { code: 'C', description: 'Checking' },
            { code: 'S', description: 'Savings' },
        ]
    })
], BankAccount.prototype, "accountType", void 0);
__decorate([
    FieldData({ recordTypeId })
], BankAccount.prototype, "accountHolderName", void 0);
__decorate([
    FieldData({ recordTypeId })
], BankAccount.prototype, "bankName", void 0);
__decorate([
    FieldData({ recordTypeId })
], BankAccount.prototype, "bankBranch", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'TIN' })
], BankAccount.prototype, "tin", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true })
], BankAccount.prototype, "accountHolderAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'phone-number' })
], BankAccount.prototype, "accountHolderPhone", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'email' })
], BankAccount.prototype, "accountHolderEmail", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'integer' })
], BankAccount.prototype, "lastSequenceUsed", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', label: 'Claim Filter', jsonStorage: true })
], BankAccount.prototype, "claimFilterGroup", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], BankAccount.prototype, "isDisabled", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], BankAccount.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], BankAccount.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], BankAccount.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], BankAccount.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], BankAccount.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], BankAccount.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], BankAccount.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], BankAccount.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], BankAccount.prototype, "deletedUTC", void 0);
BankAccount = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Bank Account',
        displayNamePlural: 'Bank Accounts',
        supports: {
            files: true,
            notes: true
        },
        roleCategory: RoleCategories.BankAccounts,
        recordHistoryConfig: {
            enable: true
        },
        dataModelCustomization: {
            hideFromAdmin: true,
            allowCustomFields: false
        },
        lookupProperties: { resultProperties: ['name'] },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'name', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'accountType', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'isDisabled', filterType: FilterType.Equals }
        ]
    })
], BankAccount);
export { BankAccount };
