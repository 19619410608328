var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'InvoiceInstallment';
let InvoiceInstallment = class InvoiceInstallment {
    //pk
    id;
    masterInvoiceId;
    masterInvoice;
    invoiceId;
    invoice;
    createUserId;
    createUser;
    modifiedUserId;
    modifiedUser;
    createdUTC;
    modifiedUTC;
    issueDate;
    dueDate;
    amount;
    isDeposit;
    lineItems;
    status;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], InvoiceInstallment.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Invoice' })
], InvoiceInstallment.prototype, "masterInvoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], InvoiceInstallment.prototype, "masterInvoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Invoice' })
], InvoiceInstallment.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], InvoiceInstallment.prototype, "invoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], InvoiceInstallment.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], InvoiceInstallment.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], InvoiceInstallment.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], InvoiceInstallment.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], InvoiceInstallment.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], InvoiceInstallment.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], InvoiceInstallment.prototype, "issueDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], InvoiceInstallment.prototype, "dueDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], InvoiceInstallment.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], InvoiceInstallment.prototype, "isDeposit", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'InvoiceLine',
        isList: true,
        jsonStorage: false,
        disableField: true,
    })
], InvoiceInstallment.prototype, "lineItems", void 0);
__decorate([
    FieldData({ recordTypeId })
], InvoiceInstallment.prototype, "status", void 0);
InvoiceInstallment = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        displayNameSingular: 'Invoice Installment',
        displayNamePlural: 'Invoice Installments',
    })
], InvoiceInstallment);
export { InvoiceInstallment };
