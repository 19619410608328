var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
import { PayrollReportPaidStatusEnum } from './payroll-report';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
export var PolicyAuditStatus;
(function (PolicyAuditStatus) {
    PolicyAuditStatus["draft"] = "draft";
    PolicyAuditStatus["submitted"] = "submitted";
    PolicyAuditStatus["changesRequested"] = "changes-requested";
    PolicyAuditStatus["approved"] = "approved";
})(PolicyAuditStatus || (PolicyAuditStatus = {}));
const recordTypeId = 'PolicyAudit';
let PolicyAudit = class PolicyAudit {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    auditUserId;
    auditUser;
    policyholderId;
    policyholder;
    policyId;
    policy;
    invoiceId;
    invoice;
    submittedUTC;
    approvedUTC;
    submitUserId;
    submitUser;
    approveUserId;
    approveUser;
    status;
    earnedPremium;
    totalIncurred;
    lossRatio;
    paidStatusErrorReason;
    paymentData;
    paymentCleared;
    paidUTC;
    paidStatus;
    quotedPremium;
    actualPremium;
    invoicedPremium;
    auditFee;
    total;
    auditResults;
    worksheetFactor;
    worksheetFactorElements;
    lineItems;
    sourceId;
    subtotalDetails;
    costDetails;
};
__decorate([
    FieldData({ recordTypeId, label: 'Policy Audit ID', fieldType: 'uuid' })
], PolicyAudit.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAudit.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAudit.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAudit.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], PolicyAudit.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], PolicyAudit.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PolicyAudit.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PolicyAudit.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], PolicyAudit.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], PolicyAudit.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAudit.prototype, "auditUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'User' })
], PolicyAudit.prototype, "auditUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        label: 'Policyholder Id',
        referenceType: 'PolicyHolder',
    })
], PolicyAudit.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder', label: 'Policyholder' })
], PolicyAudit.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Policy' })
], PolicyAudit.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy' })
], PolicyAudit.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Invoice' })
], PolicyAudit.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], PolicyAudit.prototype, "invoice", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime', label: 'Submitted On' })
], PolicyAudit.prototype, "submittedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime', label: 'Approved On' })
], PolicyAudit.prototype, "approvedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAudit.prototype, "submitUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Submitted By', fieldType: 'User' })
], PolicyAudit.prototype, "submitUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyAudit.prototype, "approveUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Approved By', fieldType: 'User' })
], PolicyAudit.prototype, "approveUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        codeList: [
            { code: PolicyAuditStatus.approved, description: 'Approved' },
            { code: PolicyAuditStatus.draft, description: 'Draft' },
            { code: PolicyAuditStatus.changesRequested, description: 'Changes Requested' },
            { code: PolicyAuditStatus.submitted, description: 'Submitted' },
        ],
    })
], PolicyAudit.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Earned Premium', jsonStorage: true })
], PolicyAudit.prototype, "earnedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', label: 'Total Incurred', jsonStorage: true })
], PolicyAudit.prototype, "totalIncurred", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Loss Ratio', jsonStorage: true })
], PolicyAudit.prototype, "lossRatio", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payment Error Reason' })
], PolicyAudit.prototype, "paidStatusErrorReason", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payment Data', jsonStorage: true })
], PolicyAudit.prototype, "paymentData", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], PolicyAudit.prototype, "paymentCleared", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime', label: 'Paid On' })
], PolicyAudit.prototype, "paidUTC", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: PayrollReportPaidStatusEnum, jsonStorage: true })
], PolicyAudit.prototype, "paidStatus", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyAudit.prototype, "quotedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyAudit.prototype, "actualPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyAudit.prototype, "invoicedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyAudit.prototype, "auditFee", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency' })
], PolicyAudit.prototype, "total", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', label: 'Audit Results', jsonStorage: true })
], PolicyAudit.prototype, "auditResults", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: true })
], PolicyAudit.prototype, "worksheetFactor", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PayrollReportFactor', isList: true, jsonStorage: true })
], PolicyAudit.prototype, "worksheetFactorElements", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyAuditLineItem', isList: true, jsonStorage: true })
], PolicyAudit.prototype, "lineItems", void 0);
__decorate([
    FieldData({ recordTypeId, disableField: true })
], PolicyAudit.prototype, "sourceId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Subtotal Details',
        fieldType: 'QuoteSubtotalDetail',
        isList: true,
        jsonStorage: true,
    })
], PolicyAudit.prototype, "subtotalDetails", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'jsonb',
        jsonStorage: true,
        disableField: true,
        isList: true,
    })
], PolicyAudit.prototype, "costDetails", void 0);
PolicyAudit = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Policy Audit',
        displayNamePlural: 'Policy Audits',
        supports: {
            views: true,
            notes: true,
            files: true,
            docTemplates: true,
            rating: true,
        },
        trailMapTypes: [TrailMapType.HomePage],
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: false,
        },
        dataModelCustomization: {
            hideFromAdmin: false,
            preventAddCustomFields: false,
            allowCustomFields: true,
        },
        usesNewDataModel: true,
        roleCategory: RoleCategories.PolicyAudits,
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: [
                'POLICYAUDIT-PAID',
                'POLICYAUDIT-PAYMENT-FAILED',
                'POLICYAUDIT-PAYMENT-REVERSED',
                'POLICYAUDIT-SUBMITTED',
                'POLICYAUDIT-RESUBMITTED',
                'POLICYAUDIT-CHANGESREQUESTED',
                'POLICYAUDIT-APPROVED',
            ],
        },
        lookupProperties: {
            resultProperties: ['policyNumber', 'policyHolder.displayName', 'policyHolder.tenantNumber'],
            filterProperties: ['policyNumber', 'policyHolder.displayName', 'policyHolder.tenantNumber'],
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'policyHolder.displayName',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'status',
                filterType: FilterType.Equals,
            },
        ],
        recordHistoryConfig: {
            enable: true,
        },
    })
], PolicyAudit);
export { PolicyAudit };
