var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
const recordTypeId = 'Contact';
export const coreContactTypes = {
    claimant: '0190dc6a-8c86-777e-b95d-ca3b9dc65b48',
    vendor: '0190dc6a-8c86-78b2-b261-970b2668b303',
    healthcareProvider: '0190dc6a-8c86-7179-abbc-4646174a6592',
    attorney: '0190dc6a-8c86-7c5c-8d19-e56e9367ac6a',
    lawFirm: '0190dc6a-8c86-7bff-b3c1-81b99b3b07fc',
    employee: '0190dc6a-8c86-796b-a187-506e68865d51'
};
let Contact = class Contact {
    //todo: make required again
    id;
    contactTypes;
    policyholderId;
    policyholder;
    displayName;
    isPayable;
    w9onFile;
    tin;
    isCompany;
    companyName;
    firstName;
    lastName;
    jobTitle;
    email;
    phoneNumber;
    parentContactId;
    parentContact;
    is1099parent;
    billingAddress;
    mailingAddress;
    physicalAddress;
    remitAddress;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Contact.prototype, "id", void 0);
__decorate([
    FieldData({
        recordTypeId,
        codeSet: 'contact-types',
        fieldType: 'codelist',
        label: 'Contact Types',
        isList: true,
        hasColumn: true,
        jsonStorage: true
    })
], Contact.prototype, "contactTypes", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'PolicyHolder' })
], Contact.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'PolicyHolder' })
], Contact.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', hidden: ["TrailMaps"], virtualDbField: true, label: 'Name' })
], Contact.prototype, "displayName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Contact.prototype, "isPayable", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'W9 on File', fieldType: 'boolean' })
], Contact.prototype, "w9onFile", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'TIN' })
], Contact.prototype, "tin", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Contact.prototype, "isCompany", void 0);
__decorate([
    FieldData({ recordTypeId })
], Contact.prototype, "companyName", void 0);
__decorate([
    FieldData({ recordTypeId })
], Contact.prototype, "firstName", void 0);
__decorate([
    FieldData({ recordTypeId })
], Contact.prototype, "lastName", void 0);
__decorate([
    FieldData({ recordTypeId })
], Contact.prototype, "jobTitle", void 0);
__decorate([
    FieldData({ recordTypeId })
], Contact.prototype, "email", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Phone #', fieldType: 'phone-number' })
], Contact.prototype, "phoneNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Contact' })
], Contact.prototype, "parentContactId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Contact' })
], Contact.prototype, "parentContact", void 0);
__decorate([
    FieldData({ recordTypeId, label: '1099 Parent', fieldType: 'boolean' })
], Contact.prototype, "is1099parent", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Billing Address', fieldType: 'Address', jsonStorage: true })
], Contact.prototype, "billingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Mailing Address', fieldType: 'Address', jsonStorage: true })
], Contact.prototype, "mailingAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Physical Address', fieldType: 'Address', jsonStorage: true })
], Contact.prototype, "physicalAddress", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Remit Address', fieldType: 'Address', jsonStorage: true })
], Contact.prototype, "remitAddress", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Contact.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Contact.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Contact.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Contact.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Contact.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Contact.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Contact.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Contact.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Contact.prototype, "deletedUTC", void 0);
Contact = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Contact',
        displayNamePlural: 'Contacts',
        supports: {
            views: true,
            files: true,
            notes: true,
            tasks: true
        },
        security: {
            useDefaultPersonaSecurity: true,
            allowCrossPolicyholderRecords: false
        },
        roleCategory: RoleCategories.Contacts,
        recordHistoryConfig: {
            enable: true
        },
        workflow: {
            enabled: true,
            generalEvents: true
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        lookupProperties: { resultProperties: ['displayName'], filterProperties: ['displayName', 'tin'] },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'displayName', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'contactTypes', filterType: FilterType.ContainsAny },
            { recordType: recordTypeId, fieldPath: 'isCompany', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'isPayable', filterType: FilterType.Equals },
        ],
        trailMapTypes: [TrailMapType.QuickAdd, TrailMapType.DataEntry, TrailMapType.Detail, TrailMapType.HomePage],
    })
], Contact);
export { Contact };
