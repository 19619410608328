import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  Input,
  OnInit,
  ViewEncapsulation,
  inject,
} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IsLoadingService } from '@service-work/is-loading';
import { DataModelStoreService } from 'app/core/data-model/services/data-model.store';
import { DataFilterStore } from 'app/modules/data-model/services/data-filter.store';
import { RecordTypesType } from 'portal-commons/dist/data-model/record-types';
import { map, Observable, shareReplay, tap } from 'rxjs';
import { DataFilterConditionComponent } from '../data-filter-condition/data-filter-condition.component';
import { FilterCondition, QuickSearchField } from 'portal-commons/dist/data-filters/models';
import { FormBuilder, FormControl } from '@angular/forms';
import { DataViewsService } from 'app/core/dataView/data-views.service';
import { LookupResult } from 'portal-commons/dist/lookups/models';

@UntilDestroy()
@Component({
  selector: 'tb-data-filter',
  templateUrl: './data-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  styles: [
    /* language=SCSS */
    `
      tb-data-filter .filterDetailPanel {
        transition: all 225ms cubic-bezier(0, 0, 0.2, 1);
        height: auto;
        overflow: hidden;
        @apply pt-0.5 pb-0.5;
      }

      tb-data-filter:not(.expanded) .filterDetailPanel {
        max-height: 0;
        @apply pt-0 pb-0;
      }

      .data-filter .mat-dialog-container {
        overflow: visible !important;
      }
    `,
  ],
})
export class DataFilterComponent implements OnInit, AfterViewInit {
  dataModelStore = inject(DataModelStoreService);
  loadingService = inject(IsLoadingService);
  hostElem = inject(ElementRef);
  dialog = inject(MatDialog);
  public filterStore = inject(DataFilterStore);
  cdRef = inject(ChangeDetectorRef);
  builder = inject(FormBuilder);
  dataViewsService = inject(DataViewsService);

  @HostBinding('className') classBinding = 'w-full';
  @HostBinding('class') get classList(): any {
    return {
      expanded: this.expanded === true,
    };
  }

  @Input() recordType: RecordTypesType | string | undefined;
  @Input() expanded = false;
  @Input() isCollapsible = true;
  @Input() rootFieldsOnly = true;
  @Input() disableAddFilter = false;
  @Input() iconOnly = true;
  @Input() quickSearchFields: QuickSearchField[] | undefined = undefined;
  @Input() showViews = false;
  @Input() applyViewColumns = false;
  @Input() historyKey: string | undefined;
  @Input() defaultFilters: FilterCondition[] | undefined;
  @Input() defaultQuickSearchValues: { [key: string]: any } | undefined;

  insideContentPanel = false;
  quickSearchForm = this.builder.group({});

  viewControl = new FormControl();
  views$: Observable<LookupResult[]> | undefined;

  ngAfterViewInit() {
    const insideContentPanel = this.hostElem.nativeElement.closest('tb-content-panel');
    if (insideContentPanel) {
      this.insideContentPanel = true;
    }
    this.cdRef.markForCheck();
  }

  ngOnInit() {
    if (this.showViews && this.recordType) {
      this.filterStore.updateState({ applyViewColumns: this.applyViewColumns });
      this.views$ = this.dataViewsService.getAvailableViewList([(this.recordType as string).toLowerCase()]).pipe(map((views) => { return views.map(m => { return { id: m.id, _displayName: m.friendlyName }; }); }), shareReplay(1));
      this.viewControl.valueChanges.pipe(
        untilDestroyed(this),
        tap((val) => {
          this.filterStore.applyView(val);
        })
      ).subscribe();
    }

    if (!this.quickSearchFields && this.recordType && this.dataModelStore.getRecordType(this.recordType)?.quickFilterFields) {
      this.quickSearchFields = this.dataModelStore.getRecordType(this.recordType)?.quickFilterFields;
    }

    this.filterStore.initFilterState({
      recordType: this.recordType,
      filters: this.defaultFilters ?? [],
      expanded: this.expanded,
      isCollapsible: this.isCollapsible,
      rootFieldsOnly: this.rootFieldsOnly,
      historyKey: this.historyKey,
      defaultQuickSearchValues: this.defaultQuickSearchValues,
      quickSearchFields: this.quickSearchFields
    });


    this.filterStore.expanded$
      .pipe(
        tap((val) => {
          this.setExpanded(val);
        }),
      )
      .subscribe();
  }

  setExpanded(val: boolean) {
    this.expanded = val;
    this.cdRef.markForCheck();
  }

  toggleFilter() {
    this.filterStore.toggleExpanded();
  }

  removeFilter(index: number) {
    this.filterStore.removeFilter(index);
  }

  openFilterDialog(event: any, filter?: FilterCondition, index?: number) {
    console.log('openFilterDialog', filter, index);
    const targetAttr = event.target.getBoundingClientRect();
    const dialogConfig = new MatDialogConfig();

    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = index === undefined;
    dialogConfig.panelClass = 'data-filter';

    dialogConfig.data = {
      filterStore: this.filterStore,
      filter: filter,
      index: index,
    };
    dialogConfig.position = {
      top: targetAttr.y + targetAttr.height + 10 + 'px',
      left: targetAttr.x + 'px',
    };

    const dialogRef = this.dialog.open(DataFilterConditionComponent, dialogConfig);
    dialogRef
      .afterClosed()
      .pipe(
        untilDestroyed(this),
        map((data) => {
          console.log('Dialog output:', data);
          if (data && data.success && data.success === true) {
            this.applyFilter(data.filter, data.index);
          }
        }),
      )
      .subscribe();
  }

  applyFilter(filter: FilterCondition, index?: number) {
    this.filterStore.applyFilter({ filter, index });
  }

  filterSelected(event: any, filter: FilterCondition, index: number) {
    this.openFilterDialog(event, filter, index);
  }
}
