var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
export var RenewalStatus;
(function (RenewalStatus) {
    RenewalStatus["Submitted"] = "submitted";
    RenewalStatus["InReview"] = "inreview";
    RenewalStatus["Approved"] = "approved";
    RenewalStatus["Declined"] = "declined";
    RenewalStatus["Denied"] = "denied";
    RenewalStatus["Accepted"] = "accepted";
    RenewalStatus["InfoRequested"] = "inforequested";
    RenewalStatus["Bound"] = "bound";
})(RenewalStatus || (RenewalStatus = {}));
const recordTypeId = 'Renewal';
let Renewal = class Renewal {
    id;
    policyId;
    policy;
    sourcePolicyId;
    sourcePolicy;
    effectiveDate;
    expirationDate;
    policyPeriod;
    status;
    policyNumber;
    quoteName;
    agencyId;
    agency;
    agentId;
    agent;
    estimatedCommission;
    quoteDate; /*This should be the date that the rates are based off of*/
    xMod;
    worksheetFactor;
    payrollClassCodes;
    billingType;
    policyholderId;
    policyholder;
    underwriterId;
    underwriter;
    createUser;
    applicant;
    //kept in jdata for legacy usage; prefer applicant
    federalEin;
    //kept in jdata for legacy usage; prefer applicant or policyholder
    insured;
    billingScheduleTypeId;
    billingScheduleType;
    earnedPremium;
    totalIncurred;
    lossRatio;
    //kept in jdata until we support more coverages; this may shape differently
    coverages;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    expiresUTC;
    quoteId;
    quote;
    locationBasedPayroll;
    policyLocations;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Renewal.prototype, "id", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
    })
], Renewal.prototype, "policyId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Policy',
        label: 'Policy',
    })
], Renewal.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Source Policy ID', fieldType: 'uuid' })
], Renewal.prototype, "sourcePolicyId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Source Policy', fieldType: 'Policy' })
], Renewal.prototype, "sourcePolicy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Renewal.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Renewal.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policy Period' })
], Renewal.prototype, "policyPeriod", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Status',
        formatter: (v) => {
            switch (v) {
                case RenewalStatus.InReview:
                    return 'In Underwriter Review';
                case RenewalStatus.InfoRequested:
                    return 'More Info Requested';
                default:
                    return `${v[0].toUpperCase()}${v.slice(1)}`;
            }
        },
        pathOverride: `case when "{tableAlias}"."status" = 'inreview' then 'In Underwriter Review' when "{tableAlias}"."status" = 'inforequested' then 'Info Requested' else initcap("{tableAlias}"."status") end`,
    })
], Renewal.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId })
], Renewal.prototype, "policyNumber", void 0);
__decorate([
    FieldData({ recordTypeId })
], Renewal.prototype, "quoteName", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
    })
], Renewal.prototype, "agencyId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Agency',
    })
], Renewal.prototype, "agency", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
    })
], Renewal.prototype, "agentId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'Agent',
    })
], Renewal.prototype, "agent", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], Renewal.prototype, "estimatedCommission", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date' })
], Renewal.prototype, "quoteDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'decimal',
        label: 'Experience Modifier',
        jsonStorage: true,
    })
], Renewal.prototype, "xMod", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', jsonStorage: true })
], Renewal.prototype, "worksheetFactor", void 0);
__decorate([
    FieldData({
        recordTypeId,
        isList: true,
        fieldType: 'PayrollClassCode',
        jsonStorage: true,
    })
], Renewal.prototype, "payrollClassCodes", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Billing Type' })
], Renewal.prototype, "billingType", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Policyholder ID',
        fieldType: 'uuid',
        referenceType: 'PolicyHolder',
    })
], Renewal.prototype, "policyholderId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Policyholder', fieldType: 'PolicyHolder' })
], Renewal.prototype, "policyholder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Renewal.prototype, "underwriterId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Underwriter',
        fieldType: 'User',
        referenceType: 'User',
    })
], Renewal.prototype, "underwriter", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
    })
], Renewal.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Applicant', fieldType: 'Applicant', hasColumn: true })
], Renewal.prototype, "applicant", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'FEIN', jsonStorage: true })
], Renewal.prototype, "federalEin", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Insured', jsonStorage: true })
], Renewal.prototype, "insured", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'uuid',
        referenceType: 'PolicyBillingScheduleType',
    })
], Renewal.prototype, "billingScheduleTypeId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyBillingScheduleType',
        label: 'Billing Schedule Type',
    })
], Renewal.prototype, "billingScheduleType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Earned Premium', jsonStorage: true })
], Renewal.prototype, "earnedPremium", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', label: 'Total Incurred', jsonStorage: true })
], Renewal.prototype, "totalIncurred", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'decimal', label: 'Loss Ratio', jsonStorage: true })
], Renewal.prototype, "lossRatio", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Coverages',
        codeSet: 'coverages',
        fieldType: 'codelist',
        isList: true,
        jsonStorage: true,
    })
], Renewal.prototype, "coverages", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Renewal.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Renewal.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Renewal.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Renewal.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Renewal.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Expires On', fieldType: 'datetime' })
], Renewal.prototype, "expiresUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Quote ID', fieldType: 'uuid' })
], Renewal.prototype, "quoteId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Quote', fieldType: 'Quote' })
], Renewal.prototype, "quote", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Location Based Payroll',
        fieldType: 'boolean',
        jsonStorage: true,
    })
], Renewal.prototype, "locationBasedPayroll", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Policy Locations',
        isList: true,
        fieldType: 'Location',
        jsonStorage: true,
    })
], Renewal.prototype, "policyLocations", void 0);
Renewal = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Renewal',
        displayNamePlural: 'Renewals',
        usesNewDataModel: true,
        supports: {
            views: true,
            docTemplates: true,
            rating: true,
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        lookupProperties: {
            resultProperties: ['quoteName', 'policyNumber', 'effectiveDate', 'expirationDate'],
            filterProperties: ['quoteName', 'policyNumber'],
        },
        roleCategory: RoleCategories.Renewals,
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail, TrailMapType.DataEntry],
        recordHistoryConfig: {
            enable: true,
        },
    })
], Renewal);
export { Renewal };
