var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
import { TrailMapType } from '../../trail-maps/models';
export var PolicyPaymentType;
(function (PolicyPaymentType) {
    PolicyPaymentType["payment"] = "payment";
    PolicyPaymentType["logPayment"] = "log-payment";
    PolicyPaymentType["logRefund"] = "log-refund";
})(PolicyPaymentType || (PolicyPaymentType = {}));
const recordTypeId = 'PolicyPayment';
let PolicyPayment = class PolicyPayment {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    policyId;
    policy;
    quoteId;
    quote;
    policyholderId;
    policyHolder;
    invoiceId;
    invoice;
    paidDateUTC;
    amount;
    success;
    status;
    statusErrorReason;
    notes;
    policyPaymentMethodId;
    paymentType;
    refundType;
    refundReferenceNumber;
    refundPayee;
    refundDate;
    paymentMethod;
    paymentData;
    paymentCleared;
    externalPaymentReferenceId;
    payerFee;
    maskedAccountNumber;
    transactionType;
    paymentMethodType;
    referenceNumber;
    balance;
    //Unexposed Properties
    payrollReportId;
    policyAuditId;
    paymentDetail;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPayment.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPayment.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPayment.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPayment.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPayment.prototype, "policyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Policy' })
], PolicyPayment.prototype, "policy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPayment.prototype, "quoteId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Quote' })
], PolicyPayment.prototype, "quote", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPayment.prototype, "policyholderId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyHolder',
        label: 'Policyholder',
        overrideJoinProperty: 'policyholderId',
    })
], PolicyPayment.prototype, "policyHolder", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Invoice' })
], PolicyPayment.prototype, "invoiceId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Invoice' })
], PolicyPayment.prototype, "invoice", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Paid On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "paidDateUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Amount',
        fieldType: 'currency',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "amount", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Success', jsonStorage: true })
], PolicyPayment.prototype, "success", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Status', jsonStorage: true })
], PolicyPayment.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Error Reason', jsonStorage: true })
], PolicyPayment.prototype, "statusErrorReason", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Notes', jsonStorage: true })
], PolicyPayment.prototype, "notes", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyPayment.prototype, "policyPaymentMethodId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'codelist',
        codeList: [
            { code: PolicyPaymentType.payment, description: 'Payment' },
            { code: PolicyPaymentType.logPayment, description: 'Log Payment' },
            { code: PolicyPaymentType.logRefund, description: 'Log Refund' },
        ],
        jsonStorage: true,
    })
], PolicyPayment.prototype, "paymentType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'refundTypes', jsonStorage: true })
], PolicyPayment.prototype, "refundType", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], PolicyPayment.prototype, "refundReferenceNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], PolicyPayment.prototype, "refundPayee", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'date', jsonStorage: true })
], PolicyPayment.prototype, "refundDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payment Method', jsonStorage: true })
], PolicyPayment.prototype, "paymentMethod", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payment Data', jsonStorage: true })
], PolicyPayment.prototype, "paymentData", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], PolicyPayment.prototype, "paymentCleared", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'External Payment Reference Id',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "externalPaymentReferenceId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Payer Fee', jsonStorage: true })
], PolicyPayment.prototype, "payerFee", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Masked Account Number',
        jsonStorage: true,
    })
], PolicyPayment.prototype, "maskedAccountNumber", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Transaction Type', jsonStorage: true })
], PolicyPayment.prototype, "transactionType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'codelist', codeSet: 'payment-methods', jsonStorage: true })
], PolicyPayment.prototype, "paymentMethodType", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], PolicyPayment.prototype, "referenceNumber", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'currency', jsonStorage: true })
], PolicyPayment.prototype, "balance", void 0);
PolicyPayment = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Policy Payment',
        displayNamePlural: 'Policy Payments',
        recordHistoryConfig: {
            enable: true,
        },
        dataModelCustomization: {
            allowCustomFields: true,
        },
        trailMapTypes: [TrailMapType.HomePage, TrailMapType.Detail],
        lookupProperties: {
            resultProperties: ['policy.policyNumber', 'amount', 'paidDateUTC'],
            filterProperties: ['policy.policyNumber'],
        },
        supports: {
            views: true,
        },
        roleCategory: RoleCategories.PolicyPayments,
        workflow: {
            enabled: true,
            generalEvents: true,
            otherEvents: ['PAYMENT-DECLINED', 'PAYMENT-NSF', 'PAYMENT-REVERSED'],
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'policyHolder.displayName',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'policy.policyNumber',
                filterType: FilterType.Equals,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'paymentType',
                filterType: FilterType.Equals,
            },
        ],
        usesNewDataModel: true,
    })
], PolicyPayment);
export { PolicyPayment };
export var PolicyPaymentStatusEnum;
(function (PolicyPaymentStatusEnum) {
    PolicyPaymentStatusEnum["pending"] = "pending";
    PolicyPaymentStatusEnum["success"] = "success";
    PolicyPaymentStatusEnum["failed"] = "failed";
    PolicyPaymentStatusEnum["reversed"] = "reversed";
})(PolicyPaymentStatusEnum || (PolicyPaymentStatusEnum = {}));
