var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
const recordTypeId = 'Note';
let Note = class Note {
    id;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
    entryDate;
    recordType;
    recordId;
    body;
    noteCategory;
    subject;
    private;
};
__decorate([
    FieldData({ recordTypeId, label: 'Note ID', fieldType: 'uuid' })
], Note.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], Note.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Note.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], Note.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], Note.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Note.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], Note.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], Note.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], Note.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], Note.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Entry Date', fieldType: 'datetime' })
], Note.prototype, "entryDate", void 0);
__decorate([
    FieldData({ recordTypeId })
], Note.prototype, "recordType", void 0);
__decorate([
    FieldData({ recordTypeId })
], Note.prototype, "recordId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Body' })
], Note.prototype, "body", void 0);
__decorate([
    FieldData({ recordTypeId, codeSet: 'noteCategories', fieldType: 'codelist' })
], Note.prototype, "noteCategory", void 0);
__decorate([
    FieldData({ recordTypeId })
], Note.prototype, "subject", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], Note.prototype, "private", void 0);
Note = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Note',
        displayNamePlural: 'Notes',
        workflow: { enabled: true, generalEvents: true },
        recordHistoryConfig: {
            enable: true,
        },
        supports: {
            views: true,
        },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'body',
                filterType: FilterType.Contains,
            },
            {
                recordType: recordTypeId,
                fieldPath: 'noteCategory',
                filterType: FilterType.Equals,
            },
        ],
    })
], Note);
export { Note };
