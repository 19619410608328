var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { TrailMapType } from '../../trail-maps/models';
export var RatePublishStatus;
(function (RatePublishStatus) {
    RatePublishStatus["Draft"] = "draft";
    RatePublishStatus["Published"] = "published";
})(RatePublishStatus || (RatePublishStatus = {}));
const recordTypeId = 'RateTableEnhancedInstance';
let RateTableEnhancedInstance = class RateTableEnhancedInstance {
    id;
    //todo: convert to id fields for FKs
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    rateTableId;
    status;
    effectiveDate;
    expirationDate;
    publishUser;
    publishUTC;
    lookupColumns;
    rateValueColumns;
    rateDefinitions;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableEnhancedInstance.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableEnhancedInstance.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableEnhancedInstance.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], RateTableEnhancedInstance.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], RateTableEnhancedInstance.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], RateTableEnhancedInstance.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], RateTableEnhancedInstance.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], RateTableEnhancedInstance.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], RateTableEnhancedInstance.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], RateTableEnhancedInstance.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'RateTableEnhanced' })
], RateTableEnhancedInstance.prototype, "rateTableId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], RateTableEnhancedInstance.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Effective Date', fieldType: 'date' })
], RateTableEnhancedInstance.prototype, "effectiveDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Expiration Date', fieldType: 'date' })
], RateTableEnhancedInstance.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Publish User' })
], RateTableEnhancedInstance.prototype, "publishUser", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Publish Date', fieldType: 'datetime' })
], RateTableEnhancedInstance.prototype, "publishUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        isList: true,
        fieldType: 'jsonb',
        jsonStorage: true,
    })
], RateTableEnhancedInstance.prototype, "lookupColumns", void 0);
__decorate([
    FieldData({
        recordTypeId,
        isList: true,
        fieldType: 'jsonb',
        jsonStorage: true,
    })
], RateTableEnhancedInstance.prototype, "rateValueColumns", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Rate Definitions',
        isList: true,
        fieldType: 'RateTableInstanceEnhancedDefinition',
        jsonStorage: false,
    })
], RateTableEnhancedInstance.prototype, "rateDefinitions", void 0);
RateTableEnhancedInstance = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Enhanced Rate Table Instance',
        displayNamePlural: 'Enhanced Rate Table Instances',
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        usesNewDataModel: true,
        trailMapTypes: [TrailMapType.HomePage],
        supports: {
            views: true,
        },
    })
], RateTableEnhancedInstance);
export { RateTableEnhancedInstance };
