var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
import { FilterType } from '../../data-filters/models';
const recordTypeId = 'PolicyBillingScheduleType';
let PolicyBillingScheduleType = class PolicyBillingScheduleType {
    id;
    name;
    description;
    disabled;
    isSystemType;
    config;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
    invoiceRuleSetId;
    invoiceRuleSet;
    billedViaPayrollReports;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], PolicyBillingScheduleType.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyBillingScheduleType.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId })
], PolicyBillingScheduleType.prototype, "description", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], PolicyBillingScheduleType.prototype, "disabled", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], PolicyBillingScheduleType.prototype, "isSystemType", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'jsonb', jsonStorage: true, disableFieldFromFilter: true })
], PolicyBillingScheduleType.prototype, "config", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], PolicyBillingScheduleType.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], PolicyBillingScheduleType.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], PolicyBillingScheduleType.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], PolicyBillingScheduleType.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], PolicyBillingScheduleType.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], PolicyBillingScheduleType.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], PolicyBillingScheduleType.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'User' })
], PolicyBillingScheduleType.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], PolicyBillingScheduleType.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'InvoiceRuleSet' })
], PolicyBillingScheduleType.prototype, "invoiceRuleSetId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'InvoiceRuleSet' })
], PolicyBillingScheduleType.prototype, "invoiceRuleSet", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean', jsonStorage: true })
], PolicyBillingScheduleType.prototype, "billedViaPayrollReports", void 0);
PolicyBillingScheduleType = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true,
        },
        hasBackingTable: true,
        displayNameSingular: 'Billing Schedule Type',
        displayNamePlural: 'Billing Schedule Types',
        lookupProperties: { resultProperties: ['name'] },
        quickFilterFields: [
            {
                recordType: recordTypeId,
                fieldPath: 'name',
                filterType: FilterType.Contains,
            },
        ],
    })
], PolicyBillingScheduleType);
export { PolicyBillingScheduleType };
