import { Pipe, PipeTransform } from '@angular/core';

/**
 * See https://stackblogger.medium.com/improve-angular-performance-using-memoize-pipe-ea26d4619ded for examples
 */
@Pipe({
    name: 'memoize'
})
export class MemoizePipe implements PipeTransform {
    public transform<Args extends unknown[], R>(handler: (...args: Args) => R, context?: unknown, ...args: Args): R {
        return handler.call(context, ...args);
    }
}