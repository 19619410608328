<ng-container [formGroup]="this.formGroup">
  <ng-container [ngSwitch]="this.controlType$ | async">
    <ng-container *ngSwitchCase="'recordType'">
      <ng-container *ngTemplateOutlet="recordTypeFilter"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'enum'">
      <mat-form-field
        class="w-60 fuse-mat-dense no-wrapper-mb"
        [ngClass]="getFieldWidth | memoize: this:this.fieldWidth">
        <mat-label class="text-sm">{{ this.fieldLabel }}</mat-label>
        <mat-select [formControlName]="this.quickSearchField.fieldPath">
          <mat-option
            *ngFor="let item of this.enumCodes$ | async"
            [value]="item.id"
            tbLongTextOption="{{ item.displayName }}">
            {{ item.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'codelist'">
      <mat-form-field
        class="w-60 fuse-mat-dense no-wrapper-mb"
        [ngClass]="getFieldWidth | memoize: this:this.fieldWidth">
        <mat-label class="text-sm">{{ this.fieldLabel }}</mat-label>
        <mat-select [formControlName]="this.quickSearchField.fieldPath">
          <mat-option
            *ngFor="let item of this.codeSetCodes$ | async"
            [value]="item.id"
            tbLongTextOption="{{ item.displayName }}">
            {{ item.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'codeset'">
      <mat-form-field
        class="w-60 fuse-mat-dense no-wrapper-mb"
        [ngClass]="getFieldWidth | memoize: this:this.fieldWidth">
        <mat-label class="text-sm">{{ this.fieldLabel }}</mat-label>
        <mat-select [formControlName]="this.quickSearchField.fieldPath">
          <mat-option
            *ngFor="let item of this.codeSetCodes$ | async"
            [value]="item.id"
            tbLongTextOption="{{ item.displayName }}">
            {{ item.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'boolean'">
      <mat-form-field
        class="w-60 fuse-mat-dense no-wrapper-mb"
        [ngClass]="getFieldWidth | memoize: this:this.fieldWidth">
        <mat-label class="text-sm">{{ this.fieldLabel }}</mat-label>
        <mat-select [formControlName]="this.quickSearchField.fieldPath">
          <mat-option [value]="null"></mat-option>
          <mat-option value="true">True</mat-option>
          <mat-option value="false">False</mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'datetime'">
      <ng-container *ngTemplateOutlet="dateFilter"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'date'">
      <ng-container *ngTemplateOutlet="dateFilter"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'mapArea'">
      <mat-form-field
        class="w-60 fuse-mat-dense no-wrapper-mb"
        [ngClass]="getFieldWidth | memoize: this:this.fieldWidth">
        <mat-label class="text-sm">{{ this.fieldLabel }}</mat-label>
        <mat-select [formControlName]="this.quickSearchField.fieldPath">
          <mat-option
            *ngFor="let item of this.mapAreas$ | async"
            [value]="item.id"
            tbLongTextOption="{{ item.displayName }}">
            {{ item.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchCase="'recordTypeLookup'">
      <mat-form-field
        class="w-60 fuse-mat-dense no-wrapper-mb"
        [ngClass]="getFieldWidth | memoize: this:this.fieldWidth">
        <mat-label class="text-sm">{{ this.fieldLabel }}</mat-label>
        <mat-select [formControlName]="this.quickSearchField.fieldPath">
          <mat-option
            *ngFor="let item of this.recordTypes$ | async"
            [value]="item.id"
            tbLongTextOption="{{ item.displayName }}">
            {{ item.displayName }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <mat-form-field
        class="w-60 fuse-mat-dense no-wrapper-mb"
        [ngClass]="getFieldWidth | memoize: this:this.fieldWidth">
        <mat-label class="text-sm">{{ this.fieldLabel }}</mat-label>
        <input
          [formControlName]="this.quickSearchField.fieldPath"
          matInput
          (keyup.enter)="doSearch()"
          [autocomplete]="'new-' + this.quickSearchField.fieldPath"
          [placeholder]="this.quickSearchField.filterType" />
      </mat-form-field>
    </ng-container>
  </ng-container>
</ng-container>

<ng-template #recordTypeFilter [formGroup]="this.formGroup">
  <div [ngClass]="getFieldWidth | memoize: this:this.fieldWidth">
    <tb-autocomplete
      [formControlName]="this.quickSearchField.fieldPath"
      [displayItemProperty]="'_displayName'"
      matFormFieldClasses="fuse-mat-no-subscript"
      labelClass="text-sm"
      [source]="lookup"
      [label]="this.fieldLabel!">
    </tb-autocomplete>
  </div>
</ng-template>

<ng-template #dateFilter [formGroup]="this.formGroup">
  <mat-form-field class="w-40 fuse-mat-dense no-wrapper-mb" [floatLabel]="'always'">
    <mat-label class="text-sm">{{ this.fieldLabel }}</mat-label>
    <input
      matInput
      placeholder="On or after"
      [matDatepicker]="date1"
      [maskito]="options"
      [formControlName]="this.quickSearchField.fieldPath + '.param1'"
      (dateChange)="date1Changed($event)" />
    <mat-datepicker-toggle matSuffix [for]="date1"></mat-datepicker-toggle>
    <mat-datepicker #date1></mat-datepicker>
  </mat-form-field>
  <div class="w-4"></div>
  <mat-form-field class="w-40 fuse-mat-dense no-wrapper-mb" [floatLabel]="'always'">
    <mat-label class="text-sm">&nbsp;</mat-label>
    <input
      matInput
      placeholder="On or before"
      [matDatepicker]="date2"
      [maskito]="options"
      [formControlName]="this.quickSearchField.fieldPath + '.param2'"
      (dateChange)="date2Changed($event)" />
    <mat-datepicker-toggle matSuffix [for]="date2"></mat-datepicker-toggle>
    <mat-datepicker #date2></mat-datepicker>
  </mat-form-field>
</ng-template>
