import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Code } from 'portal-commons/dist/data-model/record-types/code';
import { CodeSet } from 'portal-commons/dist/data-model/record-types/code-set';
import { Observable, shareReplay } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CodesApiService {
  constructor(private httpClient: HttpClient) {}

  getCodeSets() {
    return this.httpClient.get<CodeSet[]>('api/codesets').pipe(shareReplay(1));
  }

  getCodes(codeSet: string) {
    return this.httpClient.get<Code[]>(`api/codesets/${codeSet}/codes`).pipe(shareReplay(1));
  }

  private _codes$: { [codeSet: string]: Observable<Code[]> } = {};

  getCodes$(codeSet: string) {
    if (!this._codes$[codeSet]) {
      this._codes$[codeSet] = this.httpClient
        .get<Code[]>(`api/codesets/${codeSet}/codes`)
        .pipe(shareReplay(1));
    }
    return this._codes$[codeSet];
  }

  getMultipleCodes(ids: string[], columns?: string[]) {
    return this.httpClient
      .get<Code[]>(`api/codesets/multiple/codes`, {
        params: {
          columns: (columns ?? []).join(','),
          ids: ids
            .reduce((acc: string[], cur: string) => {
              if (!acc.includes(cur)) {
                acc.push(cur);
              }
              return acc;
            }, [])
            .join(','),
        },
      })
      .pipe(shareReplay(1));
  }

  getCodesSubset(codeSet: string, filterCodes: string[]) {
    return this.httpClient
      .get<Code[]>(`api/codesets/${codeSet}/codes`, {
        params: { codes: filterCodes.join(',') },
      })
      .pipe(shareReplay(1));
  }

  getCodesFromList(ids: string[]) {
    return this.httpClient
      .get<Code[]>(`api/codes`, {
        params: {
          ids: ids.join(','),
        },
      })
      .pipe(shareReplay(1));
  }

  saveCodeSet(codeSet: Partial<CodeSet>) {
    return this.httpClient.post<CodeSet>('api/codesets/save', codeSet).pipe(shareReplay(1));
  }

  deleteCodeSet(codeSet: Partial<CodeSet>) {
    return this.httpClient.post<CodeSet>('api/codesets/delete', codeSet).pipe(shareReplay(1));
  }

  saveCode(codeSet: string, code: Partial<Code>) {
    return this.httpClient
      .post<Code>(`api/codesets/${codeSet}/codes/save`, code)
      .pipe(shareReplay(1));
  }

  deleteCode(codeSet: string, code: Partial<Code>) {
    return this.httpClient
      .post<Code>(`api/codesets/${codeSet}/codes/delete`, code)
      .pipe(shareReplay(1));
  }
}
