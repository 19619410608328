import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { filter, map } from 'rxjs';

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class UpdateAppService {
  constructor(private swUpdate: SwUpdate) {
    if (swUpdate.isEnabled) {
      console.log('swUpdate available');
      this.swUpdate.versionUpdates
        .pipe(
          filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
          map((evt) => ({
            type: 'UPDATE_AVAILABLE',
            current: evt.currentVersion,
            available: evt.latestVersion,
          })),
        )
        .subscribe((event) => {
          console.log('NEW VERSION: refreshing app', event.type, JSON.stringify(event.current), JSON.stringify(event.available));
          window.location.reload();
        });
    }
    else {
      console.log('swUpdate not available');
    }
  }
}
