var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { UserDetailsPersona, UserStatus } from '../../users/models';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'User';
export const coreUserTagKeys = [
    'adjuster',
    'auditor',
    'supervisor'
];
let User = class User {
    id;
    email;
    fullName;
    policyHolderIds;
    policyHolders;
    agencyId;
    agency;
    roleId;
    role;
    userName;
    status;
    persona;
    timezone;
    birthDate;
    addresses;
    phones;
    accessGroupIds;
    userTags;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
    deactivatedBy;
    deactivatedById;
    deactivatedDate;
};
__decorate([
    FieldData({ recordTypeId, label: 'User Id' })
], User.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], User.prototype, "email", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Name' })
], User.prototype, "fullName", void 0);
__decorate([
    FieldData({
        recordTypeId,
        referenceType: 'PolicyHolder',
        isList: true,
        hasColumn: true,
        jsonStorage: true
    })
], User.prototype, "policyHolderIds", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'PolicyHolder',
        isList: true,
        disableField: true
    })
], User.prototype, "policyHolders", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Agency' })
], User.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Agency' })
], User.prototype, "agency", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', referenceType: 'Role' })
], User.prototype, "roleId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Role' })
], User.prototype, "role", void 0);
__decorate([
    FieldData({ recordTypeId })
], User.prototype, "userName", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: UserStatus })
], User.prototype, "status", void 0);
__decorate([
    FieldData({ recordTypeId, codeEnum: UserDetailsPersona })
], User.prototype, "persona", void 0);
__decorate([
    FieldData({ recordTypeId })
], User.prototype, "timezone", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string' })
], User.prototype, "birthDate", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true, isList: true })
], User.prototype, "addresses", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Phone', jsonStorage: true, isList: true })
], User.prototype, "phones", void 0);
__decorate([
    FieldData({
        recordTypeId,
        fieldType: 'string',
        isList: true,
        hasColumn: true,
        jsonStorage: true
    })
], User.prototype, "accessGroupIds", void 0);
__decorate([
    FieldData({
        recordTypeId,
        codeSet: 'user-tags',
        fieldType: 'codelist',
        label: 'User Tags',
        isList: true,
        hasColumn: true,
        jsonStorage: true
    })
], User.prototype, "userTags", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], User.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], User.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], User.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], User.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], User.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], User.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], User.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], User.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], User.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deactivated By', fieldType: 'User' })
], User.prototype, "deactivatedBy", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], User.prototype, "deactivatedById", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deactivated On', fieldType: 'datetime' })
], User.prototype, "deactivatedDate", void 0);
User = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        idIsString: true,
        displayNameSingular: 'User',
        displayNamePlural: 'Users',
        lookupProperties: { resultProperties: ['fullName', 'email'] },
        workflow: { enabled: true, generalEvents: true },
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'fullName', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'id', filterType: FilterType.Contains },
            { recordType: recordTypeId, fieldPath: 'persona', filterType: FilterType.Equals },
            { recordType: recordTypeId, fieldPath: 'status', filterType: FilterType.Equals, width: 'small' },
            { recordType: recordTypeId, fieldPath: 'userTags', filterType: FilterType.ContainsAny },
            { recordType: recordTypeId, fieldPath: 'agency', filterType: FilterType.Equals, width: 'large' },
            //{ recordType: recordTypeId, fieldPath: 'policyHolders', filterType: FilterType.ContainsAny, width: 'large' },
        ]
    })
], User);
export { User };
