//update an object with option to replace arrays instead of concatenating
export function deepMerge(base, update, combineArrays = false, excludeProps = []) {
    const result = base;
    Object.getOwnPropertyNames(update)
        .filter((key) => !key.includes('.'))
        .filter((key) => !excludeProps.includes(key))
        .forEach((key) => {
        if (update[key] === null ||
            update[key] === 'DELETE' ||
            update[key] === '00000000-0000-0000-0000-000000000000') {
            // Handle property removal for 'null' or 'DELETE'
            delete result[key];
        }
        else if (!result[key]) {
            result[key] = update[key];
        }
        else if (typeof update[key] !== 'object') {
            result[key] = update[key];
        }
        else if (Array.isArray(update[key])) {
            result[key] = combineArrays ? [...result[key], ...update[key]] : update[key];
        }
        else {
            result[key] = deepMerge(result[key], update[key], combineArrays, excludeProps);
        }
    });
    return result;
}
export function setValueByPath(obj, path, value) {
    let i = 0;
    const parts = path.split('.');
    for (i = 0; i < parts.length - 1; i++) {
        if (!obj[parts[i]]) {
            obj[parts[i]] = {};
        }
        obj = obj[parts[i]];
    }
    obj[parts[i]] = value;
}
export function getFirstNonEmptyItem(...items) {
    for (const item of items) {
        if (item === null || item === undefined) {
            continue;
        }
        if (typeof item !== 'object') {
            return item;
        }
        if (Object.keys(item).length === 0) {
            continue;
        }
        return item;
    }
    return undefined;
}
export function getObjValueFromPath(model, path) {
    try {
        if (model[path] !== undefined) {
            return model[path];
        }
        return path.split('.').reduce((a, b) => a[b], model);
    }
    catch (err) {
        //console.error(`Unable to infer path value ${path}`);
        return null;
    }
}
