var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'DirectDownload';
let DirectDownload = class DirectDownload {
    id;
    createdUTC;
    bucket;
    key;
    fileName;
    expiresUTC;
    recipients;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], DirectDownload.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], DirectDownload.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId })
], DirectDownload.prototype, "bucket", void 0);
__decorate([
    FieldData({ recordTypeId })
], DirectDownload.prototype, "key", void 0);
__decorate([
    FieldData({ recordTypeId })
], DirectDownload.prototype, "fileName", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], DirectDownload.prototype, "expiresUTC", void 0);
__decorate([
    FieldData({ recordTypeId, isList: true, fieldType: 'jsonb', jsonStorage: true, hasColumn: true })
], DirectDownload.prototype, "recipients", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'datetime' })
], DirectDownload.prototype, "deletedUTC", void 0);
DirectDownload = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        usesNewDataModel: true,
        displayNameSingular: 'Direct Download',
        displayNamePlural: 'Direct Downloads',
        dataModelCustomization: {
            hideFromAdmin: true
        }
    })
], DirectDownload);
export { DirectDownload };
