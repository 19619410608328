var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FilterType } from '../../data-filters/models';
import { RoleCategories } from '../../roleEnums';
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'ScriptGroup';
let ScriptGroup = class ScriptGroup {
    id;
    name;
    isDisabled;
    areas;
    recordTypes;
    typescript;
    createUser;
    createUserId;
    createdUTC;
    modifiedUser;
    modifiedUserId;
    modifiedUTC;
    deleteUser;
    deleteUserId;
    deletedUTC;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], ScriptGroup.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId })
], ScriptGroup.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'boolean' })
], ScriptGroup.prototype, "isDisabled", void 0);
__decorate([
    FieldData({
        recordTypeId, isList: true, fieldType: 'codelist', codeList: [
            { code: 'document-templates', description: 'Document Templates' },
            { code: 'script-actions', description: 'Script Actions' },
            { code: 'rating-formulas', description: 'Rating Formulas' },
        ], jsonStorage: true, hasColumn: true
    })
], ScriptGroup.prototype, "areas", void 0);
__decorate([
    FieldData({
        recordTypeId, isList: true, fieldType: 'recordTypeLookup', jsonStorage: true, hasColumn: true
    })
], ScriptGroup.prototype, "recordTypes", void 0);
__decorate([
    FieldData({ recordTypeId, hidden: ['All'] })
], ScriptGroup.prototype, "typescript", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created By', fieldType: 'User' })
], ScriptGroup.prototype, "createUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], ScriptGroup.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Created On', fieldType: 'datetime' })
], ScriptGroup.prototype, "createdUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified By', fieldType: 'User' })
], ScriptGroup.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], ScriptGroup.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Modified On', fieldType: 'datetime' })
], ScriptGroup.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted By', fieldType: 'User' })
], ScriptGroup.prototype, "deleteUser", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'string', referenceType: 'User' })
], ScriptGroup.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({ recordTypeId, label: 'Deleted On', fieldType: 'datetime' })
], ScriptGroup.prototype, "deletedUTC", void 0);
ScriptGroup = __decorate([
    RecordTypeData({
        id: recordTypeId,
        usesNewDataModel: true,
        dataModelCustomization: {
            hideFromAdmin: true
        },
        hasBackingTable: true,
        displayNameSingular: 'Script Group',
        displayNamePlural: 'Script Groups',
        roleCategory: RoleCategories.Workflows,
        quickFilterFields: [
            { recordType: recordTypeId, fieldPath: 'name', filterType: FilterType.Contains, },
            { recordType: recordTypeId, fieldPath: 'areas', filterType: FilterType.ContainsAny, },
            { recordType: recordTypeId, fieldPath: 'recordTypes', filterType: FilterType.ContainsAny, },
        ],
    })
], ScriptGroup);
export { ScriptGroup };
