var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { FieldData, RecordTypeData } from '../meta-data-decorators';
const recordTypeId = 'Agent';
let Agent = class Agent {
    id;
    createUserId;
    modifiedUserId;
    deleteUserId;
    createUser;
    createdUTC;
    modifiedUser;
    modifiedUTC;
    deleteUser;
    deletedUTC;
    userId;
    user;
    agencyId;
    agency;
    addresses;
    phones;
    appointedDate;
    expirationDate;
    name;
    agentNumber;
    email;
    status;
};
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Agent.prototype, "id", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Agent.prototype, "createUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Agent.prototype, "modifiedUserId", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid' })
], Agent.prototype, "deleteUserId", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Agent.prototype, "createUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Created On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Agent.prototype, "createdUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Agent.prototype, "modifiedUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Modified On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Agent.prototype, "modifiedUTC", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted By',
        fieldType: 'User',
        jsonStorage: true,
    })
], Agent.prototype, "deleteUser", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Deleted On',
        fieldType: 'datetime',
        jsonStorage: true,
    })
], Agent.prototype, "deletedUTC", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agent.prototype, "userId", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agent.prototype, "user", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'uuid', jsonStorage: true })
], Agent.prototype, "agencyId", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: false, fieldType: 'Agency' })
], Agent.prototype, "agency", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Address', jsonStorage: true, isList: true })
], Agent.prototype, "addresses", void 0);
__decorate([
    FieldData({ recordTypeId, fieldType: 'Phone', jsonStorage: true, isList: true })
], Agent.prototype, "phones", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Appointed Date',
        fieldType: 'date',
        jsonStorage: true,
    })
], Agent.prototype, "appointedDate", void 0);
__decorate([
    FieldData({
        recordTypeId,
        label: 'Expiration Date',
        fieldType: 'date',
        jsonStorage: true,
    })
], Agent.prototype, "expirationDate", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agent.prototype, "name", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agent.prototype, "agentNumber", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agent.prototype, "email", void 0);
__decorate([
    FieldData({ recordTypeId, jsonStorage: true })
], Agent.prototype, "status", void 0);
Agent = __decorate([
    RecordTypeData({
        id: recordTypeId,
        hasBackingTable: true,
        displayNameSingular: 'Agent',
        displayNamePlural: 'Agents',
        dataModelCustomization: {
            allowCustomFields: true,
        },
        lookupProperties: { resultProperties: ['name', 'email'] },
        supports: {
            views: true,
        },
        usesNewDataModel: true,
        workflow: { enabled: true, generalEvents: true },
    })
], Agent);
export { Agent };
